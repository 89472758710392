import React from "react"
import Layout from "../components/layout"

export default ({}) => {
  return (
    <Layout>
      <section>
        <header className="main">
          <h1>Напишите нам</h1>
        </header>
        <div>
          <form action="https://getform.io/f/21cb44bc-a736-4614-87b1-d84f39ce9fdd" method="POST">
            <div className="row gtr-uniform">
              <div className="col-6 col-12-xsmall">
                <input type="text" name="name" placeholder="Имя" required />
              </div>
              <div className="col-6 col-12-xsmall">
                <input type="email" name="email" placeholder="Электронная Почта" required />
              </div>
              <div className="col-12">
                <textarea rows="6" name="message" placeholder="Сообщение..." required />
              </div>
              <div className="col-12">
                <ul className="actions">
                  <li>
                    <button type="submit" className="primary">Отправить Сообщение</button>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Layout>
  )
}
